<template>
  <div class="container">
    <h2> {{ project.projectName }} </h2>
    <a  target=”_blank” :href="this.webLink"> {{ this.project.webLink }} </a>
    <p id="description">{{ project.projectDescription }}</p>
    <div>
      <p id="tags"> {{ tagString }} </p>

    </div>
  </div>
</template>

<script>

export default {
  name: 'projDesc',
  props: ['project'],

data(){
  return{
    tagString : '',
    webLink: []
  }
},

created(){
  if (this.project.tags) {
    for (var j = 0; j < this.project.tags.length; j++) {
      this.tagString = this.tagString + this.project.tags[j] + ', '
    }

    this.tagString = this.tagString.slice(0, -2)
  }

  if (this.project.webLink) {
      if (this.project.webLink.includes('noah')) {
        this.webLink = 'mailto:' + this.project.webLink + '?subject=Hallo Noah!'
      } else {
        this.webLink = 'https://' + this.project.webLink
      }
  }



},
watch: {
   // whenever question changes, this function will run

}
}
</script>

<style scoped>

  h2{
    font-size: 25pt;
    margin: .2rem 0;
  }

  a{
    font-size: 10pt;
    font-weight: lighter;
    text-decoration: none;
    border-bottom: 1px solid #000
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px
  }

  #tags{
    font-weight: lighter;
    font-size: 12px;
  }

  @media screen and (max-width: 800px) {
    .container{
      max-width: none;
      margin-top: 50px;
    }
  }


</style>
