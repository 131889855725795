<template>
  <div class="linecontainer">
    <svg id="Linie_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 466.394 2128.284">
      <path d="M357.184-417.879C184.855-113.809,191.03,28,227.957,101.3,297.5,239.359,500.933,183.72,549.279,323.208,590.105,441,456.491,513.72,451.485,712.593c-5.936,235.8,177.454,310.105,213.05,581.983C684.754,1449,647.5,1592.4,598.175,1709.082" transform="translate(-205.253 418.618)" style="fill: none;stroke: #000"/>
    </svg>

    <svg id="Linie_2" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 954.95 1992.092">
      <path id="Pfad_2" data-name="Pfad 2" d="M303.483,1482.311C164.119,1010.205,158.764,858.42,397.747,795.47q33.409-8.8,73.787-15.535C784.125,727.177,874.268,709.744,862.3,488.2S598.78,30.3,906.648-126.991s247.209-381.974,247.209-381.974" transform="translate(-205.253 509.356)" style="fill: none;stroke: #000"/>
    </svg>


    <svg id="Linie_3" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 882.872 1988.327">
      <path id="Pfad_4" data-name="Pfad 4" d="M786.9,1651.311c454.6-201.226,575.927-298.591,474.453-538.755q-14.187-33.577-34.362-71.536c-155.719-294.3-198.9-380.653-19.19-519.334s521.686-88.857,450.178-449.357-314.343-406.5-314.343-406.5" transform="translate(-786.294 335.645)" style="fill: none;stroke: #000"/>
    </svg>


  </div>
  <div class="floatcontainer">


    <p
      v-for="letter in letterA"
      ref="letters"
      @click="letter.styleObject.display = 'none'"
      :key="letter"
      :id="letter"
      :style="letter.styleObject">

      {{letter.letter}}

    </p>

  </div>

</template>


<script>



export default {
  name: "testComponent",
  data(){
    return{
      ishidden: false,
      word: "NOAHE",
      frameCount: 0,
      timeOffset: getRandomArbitrary(1000,2000),
      letterA: [],
      styleObject: {
      }

    }
  },
  computed: {
    letterArray(){
      let array = this.word.split('');
      return array
    }
  },
  methods:{

    setup(){


      let sizeMin;
      let sizeMax;

      if(window.innerWidth>500){
        sizeMin = 30;
        sizeMax = 200;
      }else{
        sizeMin= 15;
        sizeMax = 100;
      }

      let word = this.letterArray;

      for (var i = 0; i < word.length; i++) {
        this.letterA[i] = this.createLetter(word[i], getRandomArbitrary(sizeMin, sizeMax));
      }

      //this.draw()





    },

    getClientheightWidth(){

      //if-ststement prevents errormessage --> only occurs when router changed the view (Workaround!)
      //Animation seems to work properly but after chageing view, clientHeight is undefined
      if (this.$refs.letters[0] != undefined) {
        for (var i = 0; i < this.letterA.length; i++) {

          this.letterA[i].height = this.$refs.letters[i].clientHeight;
          this.letterA[i].width = this.$refs.letters[i].clientWidth


        }
      }


    },

    draw(){

      //frameCount
      this.frameCount++;



      this.getClientheightWidth()

      for (var i = 0; i < this.letterA.length; i++) {


        let coordinates = this.calcCoordinates(this.letterA[i].vel, this.letterA[i].amp, this.letterA[i].height, this.letterA[i].width)
        coordinates.y = coordinates.y - this.letterA[i].height/2;
        coordinates.x = coordinates.x - this.letterA[i].width/2;
        this.letterA[i].styleObject.top = coordinates.y + "px";
        this.letterA[i].styleObject.left = coordinates.x + "px";




      }

      this.calcRotatation()
      window.requestAnimationFrame(this.draw);





    },
    sinusValues(velFactor, ampFactor, axis, height, width){

      let direction;
      let size;
      if (axis == "x") {
        direction = window.innerWidth;
        size = width;
      }else if (axis == "y") {
        direction = window.innerHeight;
        size = height;
      }

      let vel = (((this.frameCount + this.timeOffset) % (1/velFactor)) + 1 )* velFactor;  // velocity factor between 0 and 1 (without 0 and 1) 0 slow, 1 fast
      let amp = (direction - size)/2 * ampFactor ;           // amplitude between 0 and 1, 0 no movement 1 whole window


      let value = Math.sin(2 * Math.PI * vel) * amp;  //calculating sin wave values


      return value;
    },

    calcCoordinates(velArr, ampArr, height, width){

      let axis;
      let value = new Array();
      let coordinates = {
            x: 0,
            y: 0
          };

      for (var i = 0; i < 6; i++) {

        if (i < 3) {
          axis = "x";
        }else {
          axis = "y"
        }

        value[i] = this.sinusValues(velArr[i], ampArr[i], axis, height, width);
      }

      coordinates.x = value[0] + value[1] + value[2] + window.innerWidth/2;
      coordinates.y = value[3] + value[4] + value[5] + window.innerHeight/2;



      return coordinates;

    },

    calcRotatation(){


    for (var i = 0; i < this.letterA.length; i++) {

      let value = new Array();

      for (var j = 0; j < 3; j++) {
        let velFactor = this.letterA[i].vel[j]/5;
        let ampFactor = this.letterA[i].amp[j];
        let vel = (((this.frameCount + this.timeOffset) % (1/velFactor)) + 1 )* velFactor;  // velocity factor between 0 and 1 (without 0 and 1) 0 slow, 1 fast
        let amp = this.letterA[i].rot[j] * ampFactor;           // amplitude between 0 and 1, 0 no movement 1 whole window


        value[j]  = Math.sin(2 * Math.PI * vel) * amp;  //calculating sin wave values


      }

      let rotation = value[0] + value[1] + value[2];
      this.letterA[i].styleObject.transform = "rotate(" + rotation + "deg)";

    }


    },

    createLetter(letter, size){

      let velArray = new Array();
      let ampArray = new Array();
      let rotArray = new Array();

      for (var i = 0; i < 6; i++) {
        velArray[i] = getRandomArbitrary(0.00005,0.001)
        ampArray[i] = getRandomArbitrary(0.0,0.5)
        rotArray[i] = getRandomArbitrary(0,360*10)
      }

      ampArray[2] = 0.95 - ampArray[0] - ampArray[1];
      ampArray[5] = 0.95 - ampArray[3] - ampArray[4];
      rotArray[2] = 360*10 - rotArray[0] - rotArray[1];



      let letterObj = {
        letter: letter,
        size: size,
        styleObject: {
          fontSize: size + "px",
        },
        vel: velArray,
        amp: ampArray,
        rot: rotArray,
        height: 0,
        width: 0
      }

      return letterObj;
    }

  },
  updated(){


  },
  mounted(){
    this.setup();
    window.requestAnimationFrame(this.draw);

  },

};











function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  p{
    position: fixed;
    margin: 0;
    padding: 0;
    cursor: default;
    z-index: 100;
    color: white;
    mix-blend-mode: difference;
  }

  .floatcontainer{
    z-index: 100;

  }

  .linecontainer{
    background-color: white;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: -200

  }

  svg{
    position: fixed;
    top: -10px;
    left: 0;
    height: 100vh;
    width: 110%;
    min-height: 1000px;

  }

  path{
    stroke-width: 5px;
  }

  #Linie_1{
    left: -30vw;
  }

  #Linie_2{
    left: -20vw;
  }

  #Linie_3{
    left: 5vw;
  }

  @media screen and (max-width: 1730px) {
    #Linie_3{
      left: 0vw;
    }
  }

  @media screen and (max-width: 1500px) {

    #Linie_1{
      left: -40vw;
    }

    #Linie_2{
      left: -30vw;
    }

    #Linie_3{
      top: -40px;
      left: -12vw;
    }
}

@media screen and (max-width: 1000px) {

  #Linie_3{
    top: -40px;
    left: -20vw;
  }
}

@media screen and (max-width: 800px) {
.linecontainer{
  display: none
}
}

  </style>
