<template>

  <div class="miniNav">

    <router-link to="/">
      <img id="cross" src="../assets/img/symbols/crossSymbol.svg" alt="">
    </router-link>

  </div>

</template>

<script>
// @ is an alias to /src



export default {
  name: 'miniNav'
}
</script>

<style scoped>


  img {
    height: 25px;
    margin: 0;
    transition: 0.3s;
  }

  a{
    text-align: right;
  }




  @media (hover: hover) {

    img:hover {
      opacity: 0.8;
      transform: scale(1.2);
      transition: 0.3s
    }

  }


  @media screen and (max-width: 800px) {

    .miniNav {
      position: fixed;


    }

    img {
      margin: 10px 0 15px 0;


    }



  }




</style>
