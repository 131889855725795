<template>
  <nav>
    <nav>

    </nav>
    <router-link
        v-for="(item, index) in mainMenu"
        :key="index"
        :to="'/Projects/' + item"
        class = "navLink"
        project = "item" >

        {{ item }}

      </router-link>
  </nav>
</template>

<script>
export default {
  name: 'mainMenu',
  props: ['project'],
  data(){
    return{
      mainMenu: []
    }
  },
  created(){

    
    for (var i = 0; i < this.project.length; i++) {
      this.mainMenu[i] = this.project[i].projectName;

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  nav{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 4vh;
    right: 4vw;

  }

  @media (hover: hover) {
    nav:hover .navLink{
    opacity: 0.3;
    transition: 0.5s
    }

    nav .navLink:hover{
      opacity: 1;
      transition: 0.3s;
      transform: translateX(-2%) translateY(-2%);


    }

}



  .navLink{
    text-align: right;
    margin: 0;
    font-size: 35px;
    text-transform: uppercase;
    text-decoration: none;
    color: black;

  }

  @media screen and (max-width: 992px) {

  .navLink{
      font-size: 30px;
    }

  }
  </style>
