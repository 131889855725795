<template>


    <mainMenu :project="project"/>

</template>

<script>
// @ is an alias to /src
import mainMenu from '@/components/mainMenu.vue'


export default {
  name: 'HomeView',
  data(){
    return{
      project : []
    }
  },


  components: {
    mainMenu
  },
  created(){
    this.project = JSON.parse(localStorage.projects);
    

  }
}
</script>
