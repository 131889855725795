<template>
  <div class="loadingscreen" v-if="isLoading">
    <div class="loader" ></div>
    <p>loading Images...</p>
  </div>

<transition name="fadeIMGs">

  <div class="container" v-if="!isLoading">

    <img
      rel="preload"
      v-for="(imgURL, index) in imgURLArray"
      :key="index"
      :src="imgURL"
      alt=""
      @load="onImgsLoad">
  </div>
</transition>
</template>

<script>

export default {
  name: 'imgContainer',
  props: ['project'],
  data(){
    return{
      imgURLArray: [],
      isLoading: true
    }
  },
  methods: {
    onImgsLoad(){
      this.imgsLoaded = true
    }
  },
  created(){
    let imgArray = this.project.projectImages;
    for (var i = 0; i < imgArray.length; i++) {
      this.imgURLArray.push('https:' + imgArray[i].fields.file.url)
    }



  const images = this.imgURLArray.map(imageSrc => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageSrc;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(images).then(() => {
      console.log("Images loaded!");
      this.isLoading = false;
    }).catch(error => {
      console.error("Some image(s) failed loading!");
      console.error(error.message)
    });


  }
}

</script>

<style scoped>

  .fadeIMGs-enter-from{
    opacity: 0;
  }
  .fadeIMGs-enter-to{
    opacity: 1;
  }
  .fadeIMGs-enter-active{
    transition: 1s;
  }

  .container  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }
  img {
    width: 66%;
    margin-bottom: 40px
  }

  img:nth-child(even){
    align-self: flex-end;
  }

  .loadingscreen{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: calc(50vh - 55px);

  }

  .loader {

      border: 10px solid white;
      border-radius: 50%;
      border-top: 20px solid black;
      width: 50px;
      height: 50px;
      animation: spinner 2s linear infinite;

    }



    @keyframes spinner {

      0% { transform: rotate(0deg); }

      100% { transform: rotate(360deg); }

    }

  @media screen and (max-width: 800px) {

    img {
      width: 100%;
      margin-bottom: 20px
    }

    .loadingscreen{

      margin: 20px;

    }
  }

</style>
