<template>
  <div class="projectView">
    <div class="col colLeft">
        
        <imgContainer v-bind:project="passProject"/>
    </div>
    <div class="col colRight">
        <miniNav/>
        <projDesc v-bind:project="passProject"/>



    </div>

  </div>



</template>

<script>
// @ is an alias to /src
import miniNav from '@/components/miniNav.vue'
import projDesc from '@/components/projDesc.vue'
import imgContainer from '@/components/imgContainer.vue'


export default {
  name: 'ProjectView',
  props: ['projects', 'projectArr'],
  data(){
    return{
      route: this.$route.params.name,
      turo: "hallo",
      test: {
        text1: "Hallo Noah",
        text2: "Hallo Nochmal"
      },
      passProject:{}



    }
  },
  components: {
    miniNav,
    projDesc,
    imgContainer

  },
  methods:{



  },
  created(){

    let projects = JSON.parse(localStorage.projects);

    this.passProject = projects.find(x => x.projectName === this.route);

  }
}


</script>

<style>
  .projectView {
    display: flex;
    margin-left: 3vw;

  }

  .col{
    padding: 20px;
  }

  .colLeft{
    width: 57vw;
    height: 90vh;

  }
  .colRight{

    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content:space-between;

    padding-bottom: 4vh;
    height: calc(96vh - 20px);
    right: 3vw;
    width: 30vw;

  }

  .innerColRight{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
  }

  .images {
    background-color: green;
    height: 200%;
    width: 100%;


  }

  @media screen and (max-width: 800px) {

    .projectView {
      flex-direction: column-reverse;
      margin: 4vw;



    }

    .colRight{
    position: relative;
    right: 0;
    align-items: center;
    width: 100%;
    padding: 0;
    height: auto;
  }

    .colLeft{
      align-items: center;
      width: 100%;
      height: auto;
      padding: 0;
    }

  }



</style>
