import { createApp } from 'vue'
import App from './App.vue'
import router from './router'










//get Data from CMS



function getData(){
  return new Promise((resolve) => {
var contentful = require('contentful');

let projectArray = []
var client = contentful.createClient({
  space: 'iigrp4geg08k',
  accessToken: 'PA6AYiXGnOcSVWaJtwRpVchRroHSo5MVvxCXH2KMCUs',
});





client.getEntries()
.then(function (entries) {
  entries.items.forEach(function (entry) {
        if (entry.sys.contentType.sys.id == 'project') {
         projectArray.push(entry.fields)
        }


  });
    projectArray.sort((a, b) => {
      return a.index - b.index;
    });
    resolve(projectArray)
});

})
}

getData().then((data) => {
  localStorage.projects = JSON.stringify(data);
  createApp(App).use(router).mount('#app')

})
