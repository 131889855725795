<template>
<div class="app">


    <letterAnimation />
    <router-view  v-slot="{ Component, route }">
    <transition
      :enter-active-class="route.meta.enterClass"
      :leave-active-class="route.meta.leaveClass"
      mode = "out-in"

    >
      <component :is="Component" />
    </transition>

  </router-view>



</div>

</template>

<script>

import letterAnimation from '@/components/letterAnimation.vue'
import 'animate.css';


export default {
  name: 'App',
  components: {
    letterAnimation

  },
  data(){
    return{
      projectArr: []
    }
  },
  methods: {


  },






}



</script>

<style>

html {
  background-color: white;
}

#app {
  font-family: "Helvetica Neue", Helvetica;
  font-weight: bold;
  color: black;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}



a {
  color: black
}



:root {
  --animate-duration: 400ms;

}

.app{

}




</style>
