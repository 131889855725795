import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProjectView from '../views/ProjectView.vue'
import projects from '@/assets/testJson.json'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      enterClass: 'animate__animated animate__fadeInUp',
      leaveClass: 'animate__animated animate__fadeOutDown'
    },
    props: {
      projects: projects

    }
  },
  {
    path: '/Projects/:name',
    name: 'ProjectView',
    component: ProjectView,
    meta:{
      enterClass: 'animate__animated animate__fadeInUp',
      leaveClass: 'animate__animated animate__fadeOutDown'
    },
    props: {
      projects: projects
    }
  }
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
